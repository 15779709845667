import { api, apiMethods, apiTags, listId } from '../api'

const rolesApi = api.injectEndpoints({
    endpoints: builder => ({
        getRoles: builder.query({
            query: ({ id, ...params }) => ({
                url: `roles${id ? '/' + id : ''}`,
                params
            }),
            providesTags: result => result?.rows
            ?   [
                    ...result?.rows.map(({ id }) => ({ type: apiTags.roles, id })),
                    { type: apiTags.roles, id: listId }
                ]
            :   [{ type: apiTags.roles, id: listId }]
        }),
        deleteRole: builder.mutation({
            query: ({ id }) => ({
                url: `roles/${id}`,
                method: apiMethods.delete
            }),
            invalidatesTags: [{ type: apiTags.roles, id: listId }, { type: apiTags.departments, id: listId }]
        }),
        addRole: builder.mutation({
            query: body => ({
                url: 'roles',
                method: apiMethods.post,
                body
            }),
            invalidatesTags: [{ type: apiTags.roles, id: listId }, { type: apiTags.departments, id: listId }]
        }),
        editRole: builder.mutation({
            query: ({ id, ...body }) => ({
                url: `roles/${id}`,
                method: apiMethods.put,
                body 
            }),
            invalidatesTags: [{ type: apiTags.roles, id: listId }, { type: apiTags.departments, id: listId }]
        })
    })
})

export const { 
    useGetRolesQuery, 
    useLazyGetRolesQuery, 
    useDeleteRoleMutation, 
    useAddRoleMutation, 
    useEditRoleMutation 
} = rolesApi