import { Row, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'

export const Loader = ({
    size='64px',
    ...props
}) => {
    return (
        <Row 
            align='middle' 
            justify='center'
            {...props}
        >
            <Spin indicator={<LoadingOutlined style={{ fontSize: size }}/>}/>
        </Row>
    )
}
