import { UserOutlined, MoreOutlined} from '@ant-design/icons'
import { Avatar, Badge, Button, Layout, Row, Space, Tooltip} from 'antd'
import React, { useEffect } from 'react'
import { useGetNotificationsQuery } from '../../app/endpoints/notificationsApi'
import { ARR } from '../../constants/dummyObjects'
import { useCurrentUser } from '../../hooks/useCurrentUser'
import { useToggleDrawer } from '../../hooks/useToggleDrawer'
import { UserProfile } from '../drawers/UserProfile/UserProfile'
import styles from './Navbar.module.less'

export const Navbar = React.memo(() => {
    const authUser = useCurrentUser()
    const { openDrawer } = useToggleDrawer()

    const { notifications, isNotificationsLoading } = useGetNotificationsQuery(undefined, {
        selectFromResult: result => ({
            notifications: result.data ?? ARR,
            isNotificationsLoading: result.isFetching
        })
    })

    useEffect(() => {
        if ('setAppBadge' in navigator) navigator?.setAppBadge(notifications.length)
    }, [ notifications ])
    
    return (
        <Layout.Header 
            className={styles.root}
        >
            <Row 
                justify='end' 
                align='middle'
            >
                <Space 
                    size='middle'
                >
                    <span 
                        className={styles.name}
                    >
                        {authUser.name}
                    </span>
                    <Badge 
                        count={notifications.length}
                        title='Уведомления'
                    >
                        <Avatar 
                            shape='square' 
                            size='large' 
                            icon={<UserOutlined/>} 
                            src={authUser.avatar}
                        />
                    </Badge>
                    <Tooltip 
                        title='Профиль'
                    >
                        <Button 
                            shape='circle' 
                            icon={<MoreOutlined />}
                            onClick={openDrawer}
                        />
                    </Tooltip>
                </Space>
                <UserProfile 
                    authUser={authUser}
                    notifications={notifications}
                    isNotificationsLoading={isNotificationsLoading}
                />
            </Row>
        </Layout.Header>
    )
})
