import './styles/app.less'
import ruRU from 'antd/lib/locale/ru_RU'
import 'moment/locale/ru'
import { Loader } from './components/Loader'
import { AppRouter } from './components/AppRouter'
import { useLazyCheckQuery } from './app/endpoints/authApi'
import { useEffect, useState } from 'react'
import { ConfigProvider } from 'antd'
import { useCurrentUser } from './hooks/useCurrentUser'
import { getPushToken } from './firebase'
import { useSubscribeToPushMutation, useUpdatePushTokenMutation } from './app/endpoints/subscriptionApi'
import { lsAuth, lsFCM } from './constants/localStorageVars'

function App() {
    const authUser = useCurrentUser()
    const [ pushToken, setPushToken ] = useState(false)
    const [ checkAuth, { isLoading } ] = useLazyCheckQuery()
    const [ subscribe ] = useSubscribeToPushMutation()
    const [ updateSubscription] = useUpdatePushTokenMutation()


    useEffect(() => {
        if (localStorage.getItem(lsAuth)) {
            checkAuth()
        }
    }, [ checkAuth ])


    useEffect(() => {
        if (authUser.isAuth) {
            getPushToken(setPushToken)
        }
    }, [ authUser.isAuth ])

    useEffect(() => {
        if (pushToken) {
            const oldToken = localStorage.getItem(lsFCM.oldToken)
            const newToken = localStorage.getItem(lsFCM.token)
    
            if (oldToken) {
                updateSubscription({
                    oldToken,
                    newToken
                })
    
                localStorage.removeItem(lsFCM.oldToken)
            } else {
                subscribe({
                    token: newToken
                })
            }
        }
    }, [ pushToken, subscribe, updateSubscription ])

    return (
        <ConfigProvider locale={ruRU} >
            {isLoading && !authUser.isAuth
            ?   <Loader className='vh100'/>
            :   <AppRouter authUser={ authUser }/>
            }
        </ConfigProvider>
    )
}

export default App
