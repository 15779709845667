import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import { store } from './app/store'
import { HistoryRouter } from './components/HistoryRouter'
import { history } from './routes'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './firebase'

ReactDOM.render(
    <Provider store={store}>
        <HistoryRouter history={history}>
            <App />
        </HistoryRouter>
    </Provider>,
    document.getElementById('root')
)

serviceWorkerRegistration.register()