import React from 'react'
import { Modal } from 'antd'
import { EditProfileForm } from '../forms/user/EditProfileForm'
import { useCurrentUser } from '../../hooks/useCurrentUser'

const okButtonProps = {
    form:'editProfileForm', 
    key: 'submit', 
    htmlType: 'submit'
}

export const EditProfileModal = ({
    visible,
    user = {}, 
    cancel, 
    editFunction, 
    isLoading
}) => {
    const authUser = useCurrentUser()

    return (
        <Modal 
            centered 
            destroyOnClose={true}
            open={visible} 
            confirmLoading={isLoading}
            title='Редактировать профиль'
            okText='Сохранить'
            okType='primary'
            cancelText='Закрыть'
            onCancel={cancel}
            okButtonProps={okButtonProps}
        >
            <EditProfileForm 
                user={user} 
                authUser={authUser} 
                editProfile={editFunction}
            />
            {user.id === authUser.id &&
                <p className='notes'>
                    Для установки нового пароля или смены адреса почты - необходимо указать действующий пароль.
                </p>
            }
        </Modal>
    )
}