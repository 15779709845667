import React from 'react'
import { Form, Input, Button } from 'antd'
import { PhoneOutlined, MailOutlined } from '@ant-design/icons'
import ValidationRules from '../../../../utils/ValidationRules'
import { useRestoreMutation } from '../../../../app/endpoints/authApi'
import { ReCaptchaFormItem } from '../items/ReCaptchaFormItem'

export const ResetPasswordForm = () => {
    const [ resetForm ] = Form.useForm()
    const [ restore, { isLoading } ] = useRestoreMutation()
    const finishHandler = values => {
        restore(values)
        resetForm.resetFields(['recaptcha'])
    }
    
    return (
        <Form 
            name='reset_password' 
            autoComplete='off'
            className='login-form'
            onFinish={finishHandler}
            form={resetForm}
        >
            <Form.Item
                name='email'
                rules={ValidationRules.email(true)}
            >
                <Input 
                    prefix={<MailOutlined/>} 
                    placeholder='E-mail'
                />
            </Form.Item>
            <Form.Item
                name='phone'
                rules={ValidationRules.phone()}
            >
                <Input 
                    prefix={<PhoneOutlined/>} 
                    placeholder='Телефон' 
                />
            </Form.Item>
            <ReCaptchaFormItem/>
            <Form.Item>
                <Button 
                    type='primary' 
                    htmlType='submit' 
                    className='w100' 
                    loading={isLoading}
                >
                    Сбросить
                </Button>
            </Form.Item>
        </Form>
    )
}
