import {
    ContainerOutlined,
    ScheduleOutlined,
    IdcardOutlined,
    BulbOutlined,
    PieChartOutlined,
    SlidersOutlined,
    TeamOutlined,
    SettingOutlined,
    SafetyOutlined,
    CloudSyncOutlined
} from '@ant-design/icons'

export const mainMenu = [
    {
        title: 'Редактор',
        link: '/editor',
        icon: <ContainerOutlined />,
        access: 'accessEditor',
    },
    {
        title: 'Расписание',
        link: '/schedule',
        icon: <ScheduleOutlined />,
        access: 'accessSchedule',
    },
    {
        title: 'Сотрудники',
        link: '/employees',
        icon: <TeamOutlined />,
        access: null,
    },
    {
        title: 'Поддержка',
        link: '/support',
        icon: <BulbOutlined />,
        access: 'accessTickets',
    },
    {
        title: 'Статистика',
        link: '/statistics',
        icon: <PieChartOutlined />,
        access: 'accessStats',
    },
    {
        title: 'Система',
        link: '/admin',
        icon: <SettingOutlined />,
        access: 'editSettings',
        sub: [
            {
                title: 'Пользователи',
                link: '/admin/users',
                icon: <IdcardOutlined />,
                access: 'createUsers',
            },
            {
                title: 'Роли и права',
                link: '/admin/roles',
                icon: <SafetyOutlined />,
                access: 'editSettings'
            },
            {
                title: 'Содержимое',
                link: '/admin/settings',
                icon: <SlidersOutlined />,
                access: 'editSettings'
            },
            {
                title: 'Синхронизация',
                link: '/admin/sync',
                icon: <CloudSyncOutlined />,
                access: 'editSettings'
            }
        ]
    }
]