import { createSlice } from '@reduxjs/toolkit'
import dateUtils from '../../utils/dateUtils'


const initialState = {
    userId: undefined,
    date: (dateUtils.nowMoment()).format(dateUtils.backendDate),
    toDate: (dateUtils.nowMoment()).add(7, 'days').format(dateUtils.backendDate),
    upcoming: false
}

const scheduleFilter = createSlice({
    name: 'scheduleFilter',
    initialState,
    reducers: {
        setScheduleFilter: (state, { payload }) => {
            state.userId = payload.userId
            state.date = payload.date
            state.toDate = payload.toDate
            state.upcoming = payload.upcoming
        }
    }
})

export const { setScheduleFilter } = scheduleFilter.actions
export const selectScheduleFilter = state => state.scheduleFilter

export default scheduleFilter.reducer