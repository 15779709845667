import { Row, Col, Card } from 'antd'
import React from 'react'
import { useSearchParams, Navigate } from 'react-router-dom'
import { useActivationQuery } from '../app/endpoints/authApi'
import { ActivationForm } from '../components/forms/user/ActivationForm'
import { Loader } from '../components/Loader'
import { Logo } from '../components/Logo'
import dateUtils from '../utils/dateUtils'

export const Activation = () => {
    const [ params ] = useSearchParams()
    const { data = {}, isLoading, isError } = useActivationQuery(params.get('key'))
    return (
        <Row 
            align='middle' 
            justify='center' 
            className='vh100'
        >
            {isLoading || isError
                ? <Loader/>
                :
                <Col align='center'>
                    <Logo 
                        height='128' 
                        width='128' 
                        fill='#1890ff'
                    />
                    <Card 
                        title={
                            data.user.activated 
                                ? 'Восстановление пароля' 
                                : 'Активация аккаунта'
                            } 
                            className='activation-card'
                        >
                        <p style={{ fontSize: '16px' }}>Привет, <b>{data.user.name}</b></p>
                        <ActivationForm 
                            activationKey={data.key}
                        />
                            {data?.user.activated 
                                ?   <p className='notes'>
                                        Если Вы не запрашивали восстновление пароля самостоятельно - игнорируйте письмо, 
                                        по ссылке из которого вы попали на эту страницу. 
                                        Через сутки после запроса ссылка перестанет быть валидной. 
                                        <br/> Запрос от {dateUtils.getDate(data.user.resetRequest)}
                                    </p>
                                :   <p className='notes'>
                                        После установки пароля Вы сможете использовать все доступные Вам привелегии в системе. 
                                        Максимальное время "жизни" сессии на каждом устройстве - 7 дней. Если вы забудете пароль - 
                                        его всегда можно восстановить при доступе к почте указанной при создании учетной записи.
                                    </p>
                            }
                    </Card>
                </Col>
            }
            {isError ? <Navigate to='/'/> : null}
        </Row>
    )
}
