import { api, apiTags } from '../api'
import es from 'eventsource'
import { API_URL, fetchParams, mutex } from '../api'
import { systemNotify } from '../../utils/notifications'
import { refresh } from '../slices/userSlice'
import { store } from '../store'
import { setOnlineUsers } from '../slices/onlineUsersSlice'
import { ARR } from '../../constants/dummyObjects'
import { history } from '../../routes'
import { lsAuth } from '../../constants/localStorageVars'

const watchEndpoints = {
    support: {
        endpointName: 'getTickets',
        forceInit: false,
        tags: [ apiTags.tickets ]
    },
    supportReplies: {
        endpointName: 'getTicketReplies',
        tags: [ apiTags.ticketReplies]
    },
    schedule: {
        endpointName: 'getSchedule',
        tags: [ apiTags.schedule ]
    }, 
    notifications: {
        endpointName: 'getNotifications',
        tags: [ apiTags.notifications ]
    },
    nodes: {
        endpointName: 'getNodes',
        tags: [ apiTags.nodes ]
    },
    singleNode: {
        endpointName: 'getSingleNode',
        tags: [ apiTags.nodeStories, apiTags.singleStory, apiTags.singleBlock ]
    }
}

const sseLive = async (arg = {}, { cacheDataLoaded, cacheEntryRemoved, dispatch }) => {
    const createEventSource = (args, token, instanse) => {
        if (instanse) {
            instanse.close()
        }

        const argsArr = []

        for (const [key, value] of Object.entries(args)) {
            if (value && key !== 'id') {
                argsArr.push(`${key}=${value}`)
            }
        }

        const esUrl = `${API_URL}/liveUpdate${argsArr.length ? `?${argsArr.join('&')}` : ''}` 

        const _eventSource = new es(esUrl,
            { 
                withCredentials: true,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })

        _eventSource.on('online', onlineHandler)
        _eventSource.on('close', closeHandler)
        _eventSource.on('updates', updatesHandler)
        _eventSource.on('redirect', redirectHandler)
        _eventSource.onerror = onErrorHandler

        return _eventSource
    }

    const closeHandler = () => eventSource.close()
    
    const onlineHandler = event => dispatch(setOnlineUsers(JSON.parse(event.data)?.online ?? ARR))

    const redirectHandler = event => {
        const { path, error } = JSON.parse(event.data)
        history.push(path)

        if (error) {
            systemNotify('error', error)
        }
    }
    
    const updatesHandler = event => {
        const { updates } = JSON.parse(event.data)
        const state = store.getState()
        const endpoints = Object.values(state.api.queries)

        updates.forEach(update => {
            const [[ endpointName, serverTimestamp ]] = Object.entries(update)    
            const candidate = watchEndpoints[endpointName]

            if (candidate) {
                const endpoint = endpoints.find(e => e.endpointName === candidate.endpointName)

                if (!endpoint && candidate.forceInit && api.endpoints[candidate.endpointName]) {
                    api.endpoints[candidate.endpointName].initiate(candidate.args)
                }

                if (endpoint && endpoint.fulfilledTimeStamp < serverTimestamp) {
                    dispatch(api.util.invalidateTags(candidate.tags))
                }
            }
        })
    }

    const onErrorHandler = async err => {
        if (err.status === 401) {
            if (!mutex.isLocked()) {
                const release = await mutex.acquire()
                try {
                    const refreshResponse = await fetch(`${API_URL}/auth/refresh`, {
                        method: 'GET',
                        ...fetchParams
                    })
                    if (refreshResponse.ok) {
                        const refreshData = await refreshResponse.json()
                        dispatch(refresh(refreshData))
                        eventSource = createEventSource(arg, refreshData.accessToken, eventSource)
                    }
                } finally {
                    release()
                }
            } else {
                await mutex.waitForUnlock()
                eventSource = createEventSource(arg, localStorage.getItem(lsAuth), eventSource)
            }
        }

        if (err.status === 400) {
            systemNotify('error', 'Ошибка API')
        }
    }

    await cacheDataLoaded
    await mutex.waitForUnlock()
    let eventSource = createEventSource(arg, localStorage.getItem(lsAuth))
    await cacheEntryRemoved
    eventSource.close()
}

const liveApi = api.injectEndpoints({
    endpoints: builder => ({
        getLiveUpdate: builder.query({
            queryFn: () => ({ data: {} }),
            onCacheEntryAdded: sseLive,
            keepUnusedDataFor: 0
        }),
    })
})

export const { useGetLiveUpdateQuery } = liveApi