import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { api } from './api'
import { notificationMiddleware } from './middleware/notificationMiddleware'
import user from './slices/userSlice'
import baseRoute from './slices/baseRouteSlice'
import scheduleFilter from './slices/scheduleFilterSlice'
import ticketFilter from './slices/ticketFilterSlice'
import drawerState from './slices/drawerSlice'
import nodeFilter from './slices/nodeFilterSlice'
import storyFilter from './slices/storyFilterSlice'
import onlineUsersSlice from './slices/onlineUsersSlice'

const rootReduser = combineReducers({
    [api.reducerPath]: api.reducer,
    user,
    baseRoute,
    scheduleFilter,
    ticketFilter,
    nodeFilter,
    drawerState,
    storyFilter,
    onlineUsersSlice
})

export const store = configureStore({
    reducer: rootReduser,  
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat([
        api.middleware, 
        notificationMiddleware
    ])
})
