import React from 'react'
import { Link } from 'react-router-dom'

export const Logo = ({
    height,
    width,
    className,
    fill,
    linkHome = true
}) => {
    const color = fill ?? 'rgb(24, 144, 255)'
    const logo = (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 500 500" height={height || "500"} width={width || "500"} className={className} style={{ display: "inline-block" }}>
        <g>
            <g>
                <path fill={color} fillRule="evenodd" d="M48.79,404.88H29.71v-58.31h19.08c9.44,0,17.05,2.69,22.82,8.08c5.83,5.45,8.75,12.47,8.75,21.08
                    c0,8.66-2.91,15.69-8.75,21.08C65.83,402.19,58.23,404.88,48.79,404.88z M35.87,352.15v47.15h12.16c8,0,14.36-2.14,19.08-6.42
                    c4.67-4.22,7-9.94,7-17.16c0-7.22-2.33-12.94-7-17.16c-4.72-4.28-11.08-6.42-19.08-6.42H35.87z"/>
                <path fill={color} fillRule="evenodd" d="M91.77,353.81c-0.78-0.83-1.17-1.83-1.17-3c0-1.17,0.39-2.16,1.17-3c0.83-0.83,1.83-1.25,3-1.25c1.17,0,2.17,0.42,3,1.25
                    c0.83,0.83,1.25,1.83,1.25,3c0,1.17-0.42,2.17-1.25,3c-0.83,0.78-1.83,1.17-3,1.17C93.6,354.98,92.61,354.59,91.77,353.81z
                    M97.77,404.88h-6v-40.24h6V404.88z"/>
                <path fill={color} fillRule="evenodd" d="M119.95,404.88h-6v-40.24h6v7.66c1.5-2.61,3.72-4.71,6.66-6.29c2.94-1.58,6.05-2.37,9.33-2.37v6.08
                    c-1.83,0-3.5,0.25-5,0.75c-2.56,0.67-4.8,1.82-6.75,3.46c-1.95,1.64-3.36,3.49-4.25,5.54V404.88z"/>
                <path fill={color} fillRule="evenodd" d="M163.49,405.88c-6.28,0-11.55-1.97-15.83-5.91c-4.22-3.89-6.33-8.94-6.33-15.16c0-6,1.89-11.02,5.66-15.08
                    c3.83-4.05,8.75-6.08,14.75-6.08c3.22,0,6.08,0.65,8.58,1.96c2.5,1.31,4.47,3.01,5.91,5.12c1.44,2.11,2.53,4.35,3.25,6.7
                    c0.72,2.36,1.08,4.74,1.08,7.12v1.67h-33.07c0.33,4.33,2.07,7.79,5.21,10.37c3.14,2.58,7.12,3.87,11.95,3.87
                    c4.67,0,8.97-1.25,12.91-3.75v5.33C174.29,404.6,169.6,405.88,163.49,405.88z M147.58,381.3h26.91c-0.17-3.11-1.33-5.91-3.5-8.41
                    c-2.05-2.55-5.22-3.83-9.5-3.83c-4,0-7.28,1.22-9.83,3.66C149.16,375.22,147.8,378.08,147.58,381.3z"/>
                <path fill={color} fillRule="evenodd" d="M209.28,405.88c-6.16,0-11.27-2-15.33-6c-4.06-4.05-6.08-9.11-6.08-15.16c0-6,2.05-11,6.16-15
                    c4.05-4.05,9.16-6.08,15.33-6.08c4.67,0,8.72,0.97,12.16,2.91v5.58c-3.61-2.05-7.39-3.08-11.33-3.08c-4.67,0-8.53,1.5-11.58,4.5
                    c-3.06,2.89-4.58,6.64-4.58,11.25c0,4.5,1.53,8.25,4.58,11.25c3.11,2.94,6.97,4.41,11.58,4.41c4.44,0,8.47-1.17,12.08-3.5v5.5
                    C218.5,404.74,214.16,405.88,209.28,405.88z"/>
                <path fill={color} fillRule="evenodd" d="M247.92,405.88c-3.72,0-6.54-1.09-8.46-3.29c-1.92-2.19-2.87-5.23-2.87-9.12v-23.58h-9.5v-5.25h9.5v-12.41h6v12.41h14.08
                    v5.25h-14.08v21.74c0,3.22,0.53,5.45,1.58,6.66c1.11,1.28,2.91,1.92,5.42,1.92c2.44,0,4.64-0.56,6.58-1.67v5.5
                    C254.45,405.27,251.7,405.88,247.92,405.88z"/>
                <path fill={color} fillRule="evenodd" d="M280.3,404.88h-6v-58.31h5.33l36.82,47.65v-47.65h6v58.31h-5.25l-36.9-47.82V404.88z"/>
                <path fill={color} fillRule="evenodd" d="M355.27,405.88c-6.28,0-11.55-1.97-15.83-5.91c-4.22-3.89-6.33-8.94-6.33-15.16c0-6,1.89-11.02,5.66-15.08
                    c3.83-4.05,8.75-6.08,14.75-6.08c3.22,0,6.08,0.65,8.58,1.96c2.5,1.31,4.47,3.01,5.91,5.12c1.44,2.11,2.53,4.35,3.25,6.7
                    c0.72,2.36,1.08,4.74,1.08,7.12v1.67h-33.07c0.33,4.33,2.07,7.79,5.21,10.37c3.14,2.58,7.12,3.87,11.95,3.87
                    c4.67,0,8.97-1.25,12.91-3.75v5.33C366.07,404.6,361.37,405.88,355.27,405.88z M339.35,381.3h26.91c-0.17-3.11-1.33-5.91-3.5-8.41
                    c-2.05-2.55-5.22-3.83-9.5-3.83c-4,0-7.28,1.22-9.83,3.66C340.94,375.22,339.58,378.08,339.35,381.3z"/>
                <path fill={color} fillRule="evenodd" d="M397.52,405.13h-5.25l-14.41-40.48h6.16l11.25,31.32l11-31.49h5.08l11.08,31.49l11.16-31.32h6.08l-14.41,40.48h-5.25
                    l-11.33-31.57L397.52,405.13z"/>
                <path fill={color} fillRule="evenodd" d="M460.62,405.88c-3.22,0-6.37-0.4-9.46-1.21c-3.08-0.8-5.48-1.79-7.21-2.96v-6.16c4.89,3.28,10.38,4.92,16.49,4.92
                    c3.44,0,6.15-0.57,8.12-1.71c1.97-1.14,2.96-2.71,2.96-4.71c0-1.72-0.94-3.14-2.83-4.25c-0.56-0.45-1.97-0.94-4.25-1.5
                    c-0.67-0.22-1.58-0.45-2.75-0.67c-1.5-0.39-2.42-0.61-2.75-0.67c-5.05-1.28-8.66-2.79-10.83-4.54c-2.17-1.75-3.25-4.29-3.25-7.62
                    c0-3.5,1.42-6.23,4.25-8.2c2.83-1.97,6.8-2.96,11.91-2.96c6.05,0,10.94,1.19,14.66,3.58v5.91c-4.55-2.72-9.36-4.08-14.41-4.08
                    c-3.39,0-5.96,0.53-7.71,1.58c-1.75,1.06-2.62,2.5-2.62,4.33c0,2,1.03,3.5,3.08,4.5c0.39,0.11,0.87,0.29,1.46,0.54
                    c0.58,0.25,1.18,0.46,1.79,0.63l1.83,0.5c1.33,0.45,2.67,0.75,4,0.92c5,1.22,8.68,2.72,11.04,4.5c2.36,1.78,3.54,4.3,3.54,7.58
                    c0,3.66-1.61,6.53-4.83,8.58C469.65,404.82,465.57,405.88,460.62,405.88z"/>
            </g>
        </g>
        <g>
            <path fill={color} fillRule="evenodd" d="M418.95,94.12V215.2l-48.11-60.54l-48.11-60.54H274.3v220.14h55.68v-121.7l48.73,60.84l48.73,60.86h46.86V94.12H418.95z"/>
        </g>
        <path fill={color} fillRule="evenodd" d="M230.61,160.17c-5.67-13.21-14.16-24.94-25.48-35.22c-11.42-10.26-24.45-17.96-39.07-23.12
            c-14.63-5.13-30.85-7.71-48.65-7.71H29.65v144.9h98.2c9.31,0,18.04-3.64,24.64-10.2c6.57-6.59,10.2-15.33,10.2-24.64
            c0-9.31-3.64-16.04-10.2-22.61c-6.59-6.59-15.33-10.2-24.64-10.2h-11.48v26.65l-59.3-44.47l59.3-41.41v26.67h11.48
            c17.99,0,34.92,7,47.65,19.73c12.73,12.73,19.75,27.66,19.75,45.65c0,17.99-7.03,34.92-19.75,47.65
            c-12.73,12.73-29.66,19.75-47.65,19.75h-98.2v42.68h87.75c18.02,0,34.35-2.55,48.95-7.62c14.63-5.1,27.57-12.73,38.78-22.87
            c11.32-10.18,19.81-21.9,25.48-35.14c5.64-13.27,8.49-28.08,8.49-44.42C239.1,188.06,236.26,173.38,230.61,160.17z"/>
        </svg>
    )

    return (
        linkHome
            ?   <Link to='/'>
                    {logo}
                </Link>
            : logo
    )
}
