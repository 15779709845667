import { api, apiMethods, apiTags, listId } from '../api'

const transformFormData = (data) => {
    let fd = new FormData()
    for (const [key, value] of Object.entries(data)) {
        if (key === 'avatar' || value) {
            fd.append(key, value)
        }
    }
    return fd
}

const baseUrl = 'users'

const userUpi = api.injectEndpoints({
    endpoints: builder => ({
        editProfile: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `${baseUrl}/${id}`,
                method: apiMethods.put,
                body: transformFormData(data)
            }),
            invalidatesTags: [{ type: apiTags.users, id: listId }]
        }),
        listUsers: builder.query({
            query: params => ({
                url: baseUrl,
                params
            }),
            providesTags: result => result?.rows
            ?   [
                    ...result?.rows.map(({id}) => ({type: apiTags.users, id})),
                    { type: apiTags.users, id: listId }
                ]
            :   [{ type: apiTags.users, id: listId }]
        }),
        deleteUser: builder.mutation({
            query: ({ id, force = false }) => (
            {
                url: `${baseUrl}${id ? '/' + id : ''}`,
                method: apiMethods.delete,
                body: {
                    force
                }
            }),
            invalidatesTags: [{ type: apiTags.users, id: listId }, { type: apiTags.roles, id: listId }]
        }),
        recoverUser: builder.mutation({
            query: id => ({
                url: `${baseUrl}${id ? '/' + id : ''}`,
                method: apiMethods.post
            }),
            invalidatesTags: [{ type: apiTags.users, id: listId }, { type: apiTags.roles, id: listId }]
        }),
        editUser: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `${baseUrl}/${id}`,
                method: apiMethods.put,
                body: transformFormData(data)
            }),
            invalidatesTags: [{ type: apiTags.users, id: listId }, { type: apiTags.roles, id: listId }]
        }),
        addUser: builder.mutation({
            query: data => ({
                url: baseUrl,
                method: apiMethods.post,
                body: data
            }),
            invalidatesTags: [{ type: apiTags.users, id: listId }, { type: apiTags.roles, id: listId }]
        })
    })
})

export const { 
    useEditProfileMutation, 
    useListUsersQuery, 
    useLazyListUsersQuery, 
    useDeleteUserMutation, 
    useRecoverUserMutation, 
    useEditUserMutation, 
    useAddUserMutation 
} = userUpi