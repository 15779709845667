import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDrawerState, setDrawerState } from '../app/slices/drawerSlice'

export const useToggleDrawer = () => {
    const dispatch = useDispatch()
    const drawerState = useSelector(selectDrawerState)
    const openDrawer = useCallback(() => dispatch(setDrawerState(true)), [dispatch])
    const closeDrawer = useCallback(() => dispatch(setDrawerState(false)), [dispatch])
    
    return { drawerState, openDrawer, closeDrawer }
}