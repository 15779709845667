import React from 'react'
import { Form, Input, Button } from 'antd'
import ValidationRules from '../../../utils/ValidationRules'
import { LockOutlined } from '@ant-design/icons'
import { useSetPasswordMutation } from '../../../app/endpoints/authApi'


export const ActivationForm = ({activationKey}) => {
    const [ setPassword, { isLoading } ] = useSetPasswordMutation()
    const finishHandler = password => setPassword({
        key: activationKey,
        ...password
    })

    return (
        <Form onFinish={finishHandler}>
            <Form.Item
                name='password'
                hasFeedback
                rules={ValidationRules.newPassword}
            >
                <Input.Password 
                    prefix={<LockOutlined/>} 
                    placeholder='Пароль'
                />
            </Form.Item>
            <Form.Item
                name='confirm'
                dependencies={['password']}
                hasFeedback
                rules={ValidationRules.confirmPassword()}
            >
                <Input.Password 
                    prefix={<LockOutlined/>} 
                    placeholder='Повторите пароль'
                />
            </Form.Item>
                <Form.Item>
                <Button 
                    type='primary'
                    htmlType='submit' 
                    loading={isLoading}
                >
                    Установить пароль
                </Button>
            </Form.Item>
        </Form>
    )
}
