import { createSlice } from '@reduxjs/toolkit'
import { ARR } from '../../constants/dummyObjects'

const initialState = ARR

const onlineUsers = createSlice({
    name: 'onlineUsers',
    initialState,
    reducers: {
        setOnlineUsers: (_, { payload }) => payload
    }
})

export const { setOnlineUsers } = onlineUsers.actions

export const selectOnlineUsers = state => state.onlineUsersSlice

export default onlineUsers.reducer