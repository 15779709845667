export const rowGutter = [20, 20]
export const largeRowGutter = [30, 15]
export const smallRowGutter = [10, 10]
export const spaceCenter = {
    wrap: true,
    align: 'center'
}
export const userListGrid = { 
    gutter: 16,
    xs: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 3,
    xxl: 4
}
export const ticketCardGrid = {
    xxl: 12,
    xl: 12,
    lg: 12,
    md: 24,
    sm: 24,
    xs: 24
}
export const baseFormLayout = {
    wrapperCol: {
        span: 16
    },
    labelCol: {
        span: 8
    }
}
export const tableScrollX = { x: true }
export const storyAndTagsSettingsRow = { 
    xs: 8, 
    sm: 16, 
    md: 24 
}
export const storyTypesColumn = {
    xxl: {
        span: 18
    },
    xl: {
        span: 16
    },
    md: {
        span: 24
    }
}
export const tagsColumn = {
    xxl: { 
        span: 6 
    },
    xl: { 
        span: 8 
    },
    md: { 
        span: 24 
    },
    sm: { 
        span: 24 
    },
    xs: { 
        span: 24 
    }
}