import { Avatar, Space, Tooltip } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import React from 'react'
import { spaceCenter } from '../constants/layouts'
import { useGetOnline } from '../hooks/useGetOnline'

export const OnlineUsers = ({
    maxCount = 5,
    route,
    label,
    className
}) => {
    const showUsers = useGetOnline(route)
    
    return (
        <Space
            {...spaceCenter}
            className={className}
        >
            {label}
            <div
                className='h2rem'
            >
                <Avatar.Group
                    maxCount={maxCount}
                >
                    {showUsers.map(user => (
                        <Tooltip
                            title={user.name}
                            key={user.id}
                        >
                            <Avatar
                                src={user.avatar}
                                icon={<UserOutlined/>} 
                            />
                        </Tooltip>
                    ))}
                </Avatar.Group>
            </div>
        </Space>
    )
}
