	import React, { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import appRoutes from '../routes'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { setBaseRoute } from '../app/slices/baseRouteSlice'

export const AppRouter = ({ authUser }) => {
    const dispatch = useDispatch()
    const accessControlledRoutes = useMemo(
        () => appRoutes.accessControl(authUser),
        [authUser],
    )
    const baseRoute = useMemo(() => authUser.isAuth ? accessControlledRoutes[0]?.children[0].path : '/'
    , [accessControlledRoutes, authUser])
    useEffect(() => dispatch(setBaseRoute(baseRoute)), [dispatch, baseRoute])
    const routes = useRoutes(authUser.isAuth ? accessControlledRoutes : appRoutes.public)

    return (
        <>
            {routes}
        </>
    )
}
