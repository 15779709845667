import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectScheduleFilter, setScheduleFilter } from '../app/slices/scheduleFilterSlice'

export const useScheduleFilterParams = () => {
    const dispatch = useDispatch()
    const scheduleFilterParams = useSelector(selectScheduleFilter)
    const setScheduleFilterParams = useCallback(payload => {
        const data = payload instanceof Function ? payload(scheduleFilterParams) : payload
        dispatch(setScheduleFilter(data))
    }, [dispatch, scheduleFilterParams])
    
    return {
        scheduleFilterParams: useMemo(() => scheduleFilterParams, [scheduleFilterParams]), 
        setScheduleFilterParams
    }
}