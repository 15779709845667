import React, { useCallback, useMemo } from 'react'
import { Menu } from 'antd'
import { profileMenu } from '../../menus/profileMenu'
import { useLogoutMutation } from '../../app/endpoints/authApi'
import { useNavigate } from 'react-router-dom'
import { useScheduleFilterParams } from '../../hooks/useScheduleFilterParams'
import { useToggleDrawer } from '../../hooks/useToggleDrawer'
import { useGetMenuItems } from '../../hooks/useGetMenuItems'
import dateUtils from '../../utils/dateUtils'
import { useStoryFilterParams } from '../../hooks/useStoryFilterParams'

export const ProfileMenu = React.memo(({ user, editProfileCallback }) => {
    const { closeDrawer } = useToggleDrawer()
    const navigate = useNavigate()
    const { setScheduleFilterParams } = useScheduleFilterParams()
    const [ logout ] = useLogoutMutation()
    const { setStoryFilterParams } = useStoryFilterParams()
    const editMyProfile = useCallback(() => editProfileCallback(true), [editProfileCallback])
    const openMyStats = useCallback(() => {}, [])

    const openMyStories = useCallback(() => {
        setStoryFilterParams(params => ({
            ...params,
            authorId: user.id
        }))
        navigate('/editor/search')
        closeDrawer()
    }, [navigate, setStoryFilterParams, user, closeDrawer])

    const openMySchedule = useCallback(() => {
        setScheduleFilterParams({
            date: (dateUtils.nowMoment()).format(dateUtils.backendDate),
            toDate: (dateUtils.nowMoment()).add(1, 'month').format(dateUtils.backendDate),
            userId: user.id,
            upcoming: true
        })
        navigate('/schedule')
        closeDrawer()
    }, [closeDrawer, navigate, setScheduleFilterParams, user])

    const logOut = useCallback(() => logout(), [logout])

    const menuOptions = useMemo(() => profileMenu(
        editMyProfile, 
        openMyStats, 
        user.permissions.editStories ? openMyStories : null, 
        user.scheduleRoleId ? openMySchedule : null, 
        logOut).filter(i => i.onClick), [editMyProfile, openMyStats, openMyStories, openMySchedule, logOut, user])

    const siderMenuItems = useGetMenuItems(menuOptions)

    return (
        <Menu 
            mode='vertical' 
            selectable={false} 
            className='br-unset'
            items={siderMenuItems}
        />
    )
}
)