import { Form, Input, Upload, Select } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import ValidationRules from '../../../utils/ValidationRules'
import ImgCrop from 'antd-img-crop'
import React, { useState }  from 'react'
import { useGetRolesQuery } from '../../../app/endpoints/rolesApi'
import { checkUpload } from '../../../utils/uploadUtils'
import { ARR } from '../../../constants/dummyObjects'
import { baseFormLayout } from '../../../constants/layouts'

export const EditProfileForm = ({
    user = {}, 
    authUser, 
    editProfile
}) => {
    const [ needsPassword, setNeedsPassword ] = useState(false)
    const [ requireConfirm, setRequireConfirm ] = useState(false)
    const [ avatarImg, setAvatarImg ] = useState(null)
    const { roleOptions, rolesLoading } = useGetRolesQuery({
        hidden: true
    }, {
        selectFromResult: ({ data, ...result }) => ({
            ...result,
            roleOptions: data?.rows.map(item => ({
                label: item.role.roleName,
                value: item.role.roleId
            })) ?? ARR,
            rolesLoading: result.isFetching
        })
    })

    const uploadAction = data => {
        let fr = new FileReader()
        fr.readAsDataURL(data.file)
        fr.onload = () => {
            setAvatarImg({
                file: data.file,
                src: fr.result
            })
        }
    }

    const changeHandler = (value, values) => {
        if ((values.email !== user.email || values.newPassword.length > 0) && user.id === authUser.id) {
            if (!needsPassword) setNeedsPassword(true)
        } else {
            if (needsPassword) setNeedsPassword(false)
        }

        if (values.newPassword.length > 0) {
            if (!requireConfirm) setRequireConfirm(true)
        } else {
            if (requireConfirm) setRequireConfirm(false)
        }
    }

    const onFinishHandler = values => editProfile(
        {
            id: user.id, 
            avatar:  avatarImg?.file ?? undefined, 
            ...values
        }
    )

    return (
            <Form 
                {...baseFormLayout}
                preserve={false}
                layout='horizontal'
                onValuesChange={changeHandler}
                id='editProfileForm'
                onFinish={onFinishHandler}
            >
            <Form.Item 
                label='Аватар'
                extra='Выберите изображение профиля для загрузки. Максимальный размер файла - 5МБ'
            >   
                <ImgCrop
                    modalTitle='Кадрирование изображения'
                    modalOk='Применить'
                    modalCancel='Отменить'
                    beforeCrop={checkUpload(15)}   
                >
                    <Upload
                        listType='picture-card'
                        showUploadList={false}
                        maxCount={1}
                        customRequest={uploadAction}
                    >
                        {avatarImg || user.avatar 
                            ?   <img 
                                    src={avatarImg ? avatarImg?.src : user.avatar} 
                                    alt={user.name} 
                                    className='w100'
                                />
                            :   <UploadOutlined />
                        }
                    </Upload>
                </ImgCrop>
            </Form.Item>
            {authUser.permissions.editUsers &&
                <Form.Item
                    label='Логин'
                    name='login'
                    initialValue={user.login}
                    rules={ValidationRules.login()}
                >
                    <Input/>
                </Form.Item>
            }
            {user.id !== authUser.id && authUser.permissions.editUsers &&
                <Form.Item
                    label='Роль'
                    name='roleId'
                    initialValue={user.roleId}
                >
                    <Select
                        loading={rolesLoading}
                        options={roleOptions}
                    />
                </Form.Item>
            }
            <Form.Item 
                label='Имя/фамилия'
                name='name'
                initialValue={user.name}
                rules={ValidationRules.name()}
            >
                <Input/>
            </Form.Item>
            <Form.Item 
                label='E-mail' 
                name='email' 
                initialValue={user.email}
                rules={ValidationRules.email()}
            >
                <Input/>
            </Form.Item>
            <Form.Item 
                label='Номер телефона'
                name='phone'
                initialValue={user.phone}
                rules={ValidationRules.phone()}
            >
                <Input/>
            </Form.Item>
            <Form.Item 
                hasFeedback
                label='Новый пароль'
                name='newPassword'
                initialValue=''
                rules={ValidationRules.newPassword}
            >
                <Input type='password'/>
            </Form.Item>
            {requireConfirm &&
                <Form.Item 
                    hasFeedback
                    label='Повторите пароль'
                    name='confirm'
                    dependencies={['newPassword']}
                    rules={ValidationRules.confirmPassword(requireConfirm, 'newPassword')}
                >
                    <Input type='password'/>
                </Form.Item>
            }
            {needsPassword &&
                <Form.Item 
                    label='Текущий пароль' 
                    name='oldPassword'
                    rules={ValidationRules.password()}
                >
                    <Input type='password'/>
                </Form.Item>
            }
            </Form>
    )
}