import { Menu } from 'antd'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { mainMenu } from '../../menus/mainMenu'
import { useGetMenuItems } from '../../hooks/useGetMenuItems'

export const MainMenu = React.memo(() => {
    const location = useLocation()
    const mainMenuItems = useGetMenuItems(mainMenu)
    const curPath = `/${location.pathname.split('/')[1]}`

    return (
        <Menu 
            mode='inline'
            theme='dark'
            selectedKeys={curPath} 
            className='mt1-5rem'
            items={mainMenuItems}
        />
    )
})
