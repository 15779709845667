import React, { useState } from 'react'
import { UserOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Avatar, Drawer, Divider, Row, Col } from 'antd'
import { ProfileMenu } from '../../menu/ProfileMenu'
import { EditProfileModal } from '../../modals/EditProfileModal'
import { useEditProfileMutation } from '../../../app/endpoints/userApi'
import { NotificationsList } from '../../lists/NotificationsList'
import { useDeleteNotificationMutation } from '../../../app/endpoints/notificationsApi'
import styles from './UserProfile.module.less'
import { useToggleDrawer } from '../../../hooks/useToggleDrawer'

export const UserProfile = ({ authUser, notifications, isNotificationsLoading }) => {
    const { drawerState, closeDrawer } = useToggleDrawer()
    const [ editProfileVisible, setEditProfileVisible ] = useState(false)
    const [ editProfile, { isLoading } ] = useEditProfileMutation()
    const [ deleteNotification ] = useDeleteNotificationMutation()
    const profileVisibleHandler = () => setEditProfileVisible(false)

    return (
        <Drawer 
            open={drawerState.isOpen} 
            title='Профиль пользователя'
            onClose={closeDrawer} 
            width='300px'
            closeIcon={<CloseCircleOutlined className={styles.closeIcon}/>}
        >
            <Row align='middle' justify='center'>
                <Col align='center'>
                    <Avatar 
                        shape='circle'
                        className='profile-avatar'
                        icon={<UserOutlined/>} 
                        src={authUser.avatar}
                    />
                    <p className={styles.name}>{authUser.name}</p>
                    <p className={styles.role}>{authUser.role}</p>
                </Col>
            </Row>
            <Divider/>
            <ProfileMenu 
                user={authUser}
                editProfileCallback={ setEditProfileVisible }
            />
            <Divider/>
            <EditProfileModal 
                cancel={profileVisibleHandler} 
                visible={ editProfileVisible } 
                user={authUser} 
                editFunction={editProfile}
                isLoading={isLoading}
            />
            <NotificationsList 
                notifications={notifications}
                isLoading={isNotificationsLoading}
                deleteMutation={deleteNotification}
            />
        </Drawer>
    )
}
