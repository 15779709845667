import { createSlice } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'
import { lsAuth } from '../../constants/localStorageVars'
import { api } from '../api'

const initialState = {
    isAuth: false,
    name: '',
    login: '',
    id: '',
    phone: '',
    email: '',
    role: '',
    permissions: {},
    avatar: '',
}

const setUser = (state, { payload }) => {
    if (payload.accessToken) {
        const decoded = jwtDecode(payload.accessToken)
        if (decoded) {
            state.name = decoded.name
            state.login = decoded.login
            state.id = decoded.id
            state.phone = decoded.phone
            state.email = decoded.email
            state.role = decoded?.role?.roleName ? decoded.role.roleName : state.role
            state.permissions = Object.assign(state.permissions, decoded.permissions) 
            state.avatar = decoded.avatar ?? null
            state.isAuth = true
            if (decoded.scheduleRoleId) {
                state.scheduleRoleId = decoded.scheduleRoleId
            }
            localStorage.setItem('authToken', payload.accessToken)
        }
    } else {
        state.name = payload.name
        state.login = payload.login
        state.phone = payload.phone
        state.email = payload.email
        state.avatar = payload.avatar ?? null
    }
}

const user = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        logout: state => {
            state = Object.assign(state, initialState)
            localStorage.removeItem(lsAuth)
        },
        refresh: setUser
    },
    extraReducers: (builder) => {
        builder
        .addMatcher(
            api.endpoints.login.matchFulfilled,
            setUser
        )
        .addMatcher(
            api.endpoints.login.matchRejected,
            state => {
                state = Object.assign(state, initialState)
            }
        )
        .addMatcher(
            api.endpoints.logout.matchFulfilled,
            state => user.caseReducers.logout(state)
        )
        .addMatcher(
            api.endpoints.check.matchFulfilled,
            setUser
        )
        .addMatcher(
            api.endpoints.check.matchRejected,
            (state, action) => {
                if (!action?.meta.condition) {
                    state = Object.assign(state, initialState)
                }
            }
        )
        .addMatcher(
            api.endpoints.setPassword.matchFulfilled,
            setUser
        )
        .addMatcher(
            api.endpoints.editProfile.matchFulfilled,
            setUser
        )
    }
})

export const { logout, refresh } = user.actions

export const selectCurrentUser = state => state.user

export default user.reducer