import {
    IdcardOutlined, 
    BarChartOutlined, 
    CalendarOutlined, 
    ContainerOutlined,
    PoweroffOutlined 
    } from '@ant-design/icons'

export const profileMenu = (editMyProfile, openMyStats, openMyStories, openMySchedule, logOut) => {
    return [
        {
            title: 'Редактировать профиль',
            onClick: editMyProfile,
            icon: <IdcardOutlined />,
            access: null
        },
        {
            title: 'Мои истории',
            onClick: openMyStories,
            icon: <ContainerOutlined />,
            access: 'editStories'
        },
        {
            title: 'Предстоящие выезды',
            onClick: openMySchedule,
            icon: <CalendarOutlined />,
            access: 'accessSchedule'
        },
        {
            title: 'Статистика',
            onClick: openMyStats,
            icon: <BarChartOutlined />,
            access: 'accessStats'
        },
        {
            title: 'Выйти',
            onClick: logOut,
            icon: <PoweroffOutlined />,
            access: null
        }
    ]
}