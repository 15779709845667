import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCurrentUser } from './useCurrentUser'

export const useGetMenuItems = items => {
    const { permissions } = useCurrentUser()
    const navigate = useNavigate()
    const getItem = useCallback((item, index, children) => ({
        label: item.title,
        key: item.link ? item.link : index,
        icon: item.icon,
        onClick: item.sub ? undefined : item.onClick ? item.onClick : () => navigate(item.link),
        children
    }), [navigate])
    const accessControl = useCallback((items, permissions) => items.filter(item => !item.access || permissions[item.access]), [])

    return useMemo(() => accessControl(items, permissions).map((item, i) => {
        if (item.sub) {
            return getItem(item, i, accessControl(item.sub, permissions).map((subItem, ii) => getItem(subItem, ((i + 1)*10) + ii)))
        }

        return getItem(item, i)
    }), [accessControl, getItem, items, permissions])
}