import React from 'react'
import { Login } from '../pages/Login/Login'
import { Index } from '../pages/Index/Index'
import { Activation } from '../pages/Activation'
import { Navigate } from 'react-router-dom'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const LazyAdminSettings = React.lazy(() => import('../pages/Admin/AdminSettings/AdminSettings').then(module => ({ default: module.AdminSettings })))
const LazyEditor = React.lazy(() => import('../pages/Editor/EditorIndex.jsx').then(module => ({ default: module.EditorIndex })))
const LazyEmployees = React.lazy(() => import('../pages/Employees').then(module => ({ default: module.Employees })))
const LazySupportIndex = React.lazy(() => import('../pages/Support/SupportIndex').then(module => ({ default: module.SupportIndex })))
const LazySupportSingle = React.lazy(() => import('../pages/Support/SupportSingle').then(module => ({ default: module.SupportSingle })))
const LazyScheduleIndex = React.lazy(() => import('../pages/Schedule/ScheduleIndex').then(module => ({ default: module.ScheduleIndex })))
const LazyScheduleSingle = React.lazy(() => import('../pages/Schedule/ScheduleSingle').then(module => ({ default: module.ScheduleSingle })))
const LazyStatistics = React.lazy(() => import('../pages/Statistics').then(module => ({ default: module.Statistics })))
const LazyAdminUsers = React.lazy(() => import('../pages/Admin/AdminUsers/AdminUsers').then(module => ({ default: module.AdminUsers })))
const LazyAdminRoles = React.lazy(() => import('../pages/Admin/AdminRoles').then(module => ({ default: module.AdminRoles })))
const LazyAdminSync = React.lazy(() => import('../pages/Admin/AdminSync').then(module => ({ default: module.AdminSync })))
const LazyAdminSyncOauth = React.lazy(() => import('../pages/Admin/OathRedirect').then(module => ({ default: module.OathRedirect })))
const LazyEditorNodes = React.lazy(() => import('../pages/Editor/NodeIndex').then(module => ({ default: module.NodeIndex })))
const LazyEditorSingleNode = React.lazy(() => import('../pages/Editor/SingleNode').then(module => ({ default: module.SingleNode })))
const LazyEditorSingleStory = React.lazy(() => import('../pages/Editor/StorySingle').then(module => ({ default: module.StorySingle })))
const LazyEditorSingleBlock = React.lazy(() => import('../pages/Editor/BlockSingle/BlockSingle').then(module => ({ default: module.BlockSingle })))
const LazyEditorBlockEditing = React.lazy(() => import('../pages/Editor/BlockSingle/BlockEditing').then(module => ({ default: module.BlockEditing })))
const LazyEditorBlockView = React.lazy(() => import('../pages/Editor/BlockSingle/BlockView').then(module => ({ default: module.BlockView })))
const LazyEditorSearch = React.lazy(() => import('../pages/Editor/Search').then(module => ({ default: module.Search })))
const LazyEditorStoryBin = React.lazy(() => import('../pages/Editor/StoryBin').then(module => ({ default: module.StoryBin })))
const LazyEditorTreasury = React.lazy(() => import('../pages/Editor/Treasury').then(module => ({ default: module.Treasury })))

const routes = [
    {
        path: 'editor',
        element: <LazyEditor/>,
        access: 'accessEditor',
        children: [
            {
                path: 'nodes',
                element: <LazyEditorNodes/>,
                access: 'accessEditor'
            },
            {
                path: 'nodes/:id',
                element: <LazyEditorSingleNode/>,
                access: 'accessEditor'
            },
            {
                path: 'nodes/:nodeId/:storyId',
                element: <LazyEditorSingleStory/>,
                access: 'accessEditor'
            },
            {
                path: 'nodes/:nodeId/:storyId/:blockId',
                element: <LazyEditorSingleBlock/>,
                access: 'accessEditor',
                children: [
                    {
                        path: '',
                        element: <LazyEditorBlockView/>,
                        access: 'accessEditor'
                    },
                    {
                        path: 'edit',
                        element: <LazyEditorBlockEditing/>,
                        access: 'accessEditor'
                    },
                ]
            },
            {
                path: 'search',
                element: <LazyEditorSearch/>,
                access: 'accessEditor'
            },
            {
                path: 'bin',
                element: <LazyEditorStoryBin/>,
                access: 'manageStories'
            },
            {
                path: 'treasury',
                element: <LazyEditorTreasury/>,
                access: 'manageStories'
            },
            {
                path: '/editor/',
                element: <Navigate to='nodes'/>,
            }
        ]
    },
    { 
        path: 'schedule', 
        element: <LazyScheduleIndex/>,
        access: 'accessSchedule'
    },
    { 
        path: 'schedule/:id', 
        element: <LazyScheduleSingle/>,
        access: 'accessSchedule'
    },
    { 
        path: 'employees', 
        element: <LazyEmployees/>,
        access: null
    },
    { 
        path: 'support', 
        element: <LazySupportIndex/>,
        access: 'accessTickets'
    },
    { 
        path: 'support/:id', 
        element: <LazySupportSingle/>,
        access: 'accessTickets'
    },
    { 
        path: 'statistics', 
        element: <LazyStatistics/>,
        access: 'accessStats'
    },
    {
        path: 'admin/users', 
        element: <LazyAdminUsers/>,
        access: 'createUsers'
    },
    {
        path: 'admin/roles', 
        element: <LazyAdminRoles/>,
        access: 'editSettings'
    },
    {
        path: 'admin/settings', 
        element: <LazyAdminSettings/>,
        access: 'editSettings'
    },
    {
        path: 'admin/sync', 
        element: <LazyAdminSync/>,
        access: 'editSettings'
    },
    {
        path: 'admin/sync/oauth', 
        element: <LazyAdminSyncOauth/>,
        access: 'editSettings'
    }
]

const accessControlRoutes = (routes, user) => {
    const accessFilter = (routes, permissions) => routes.filter(route => !route.access || permissions[route.access])

    const getRoute = route => {
        if (route.children) {
            return {
                ...route,
                children: accessFilter(route.children, user.permissions).map(subRoute => getRoute(subRoute))
            }
        }

        return route
    }

    return accessFilter(routes, user.permissions).map(route => getRoute(route))
}

const appRoutes = {
    public: [
        {
            path: '/',
            element: <Login/>
        },
        {
            path: '/activation',
            element: <Activation/>
        },
        {
            path: '*',
            element: <Login/>
        }
    ],

    accessControl: user => {
        return [
                    {
                        path: '/',
                        element: <Index/>,
                        children: accessControlRoutes(routes, user)
                    }, 
                    {
                        path: '*',
                        element: <Navigate to='/'/>
                    }
                ]
    }
}

export default appRoutes 