import { Affix, Layout, Row } from 'antd'
import React from 'react'
import { Logo } from './Logo'
import { MainMenu } from './menu/MainMenu'

export const AppSider = React.memo(({ 
    overlayClass, 
    setOverlayClass,
    additionalClass
}) => {
    const onBreakPointHandler = e => e ? setOverlayClass(true) : setOverlayClass(false)

    return (
        <Affix>
            <Layout.Sider 
                theme='dark'
                className={`mh100 ${overlayClass && additionalClass}`}
                collapsible='true'
                breakpoint='md'
                onBreakpoint={onBreakPointHandler}
            >
                <Row justify='center'>
                    <Logo 
                        height='64' 
                        width='64' 
                        fill='#1890ff'
                    />
                </Row>
                <MainMenu/>
            </Layout.Sider>
        </Affix>
    )
})
