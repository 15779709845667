import { Col, Divider, Layout, Row, Space } from 'antd'
import React from 'react'
import { rowGutter } from '../../constants/layouts'
import { Logo } from '../Logo'
import { OnlineUsers } from '../OnlineUsers'
import styles from './AppFooter.module.less'

export const AppFooter = () => {
    return (
        <Layout.Footer>
            <Divider/>
            <Space
                direction='vertical'
                className='w100'
                size='large'
            >
                <Row
                    wrap
                    gutter={rowGutter}
                >
                    <Col>
                        <div
                            className={styles.footerBlock}
                        >
                            <Row
                                gutter={rowGutter}
                                align='middle'
                                wrap
                            >
                                <Col>
                                    <Logo
                                        fill='#000'
                                        height={80}
                                        width={100}
                                        linkHome={false}
                                    />
                                </Col>
                                <Col>
                                    <p className='notes m0'>Collaborative software</p>
                                    <p className='notes m0'>ver.: {process.env.REACT_APP_VERSION}</p>
                                    <p className='notes m0'>© Stepan Bondarenko</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col>
                        <div
                            className={styles.footerBlock}
                        >
                            <OnlineUsers
                                label='Онлайн:'
                            />
                        </div>
                    </Col>
                </Row>
                <Row
                    justify='space-between'
                    align='middle'
                    wrap
                    gutter={rowGutter}
                    className={styles.techStack}
                >
                    <Col>
                        <img
                            src={process.env.PUBLIC_URL + '/img/redux.svg'}
                            alt='Redux'
                        />
                    </Col>
                    <Col>
                        <img
                            src={process.env.PUBLIC_URL + '/img/express.svg'}
                            alt='ExpressJS'
                        />
                    </Col>
                    <Col>
                        <img
                            src={process.env.PUBLIC_URL + '/img/react.svg'}
                            alt='React'
                        />
                    </Col>
                    <Col>
                        <img
                            src={process.env.PUBLIC_URL + '/img/nodejs.png'}
                            alt='Node JS'
                        />
                    </Col>
                    <Col>
                        <img
                            src={process.env.PUBLIC_URL + '/img/mysql.svg'}
                            alt='MySQL'
                        />
                    </Col>
                </Row>
            </Space>
        </Layout.Footer>
    )
}
