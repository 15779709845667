import { LoginForm } from '../../components/forms/user/LoginForm'
import { ResetPasswordForm } from '../../components/forms/user/LoginForm/ResetPasswordForm'

const loginTabs = [
    {
        label: 'Войти',
        children: <LoginForm/>,
        key: 'login'
    },
    {
        label: 'Восстановить',
        children: <ResetPasswordForm/>,
        key: 'reset'
    },
]

export default loginTabs