import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false
}

const drawerState = createSlice({
    name: 'drawerState',
    initialState,
    reducers: {
        setDrawerState: (state, { payload }) => {
            state.isOpen = payload
        }
    }
})

export const { setDrawerState } = drawerState.actions

export const selectDrawerState = state => state.drawerState

export default drawerState.reducer