const defaultStringPattern = /^[a-zа-яA-ZА-ЯёЁіІїЇєЄ0-9:?()#№/,.+! "'`ʼ@_%&-]+$/
const extendedStringPattern = /^[a-zа-яA-ZА-ЯёЁіІїЇєЄ0-9\n\r:?()#№/,.+! "'`ʼ@_%&-]+$/
const limitedStringPattern = /^[a-zа-яA-ZА-ЯёЁіІїЇєЄ0-9 .'`ʼ"-]+$/
const passwordStringPattern = /^[0-9a-zA-Z!@#$%^&*.,\-_]+$/
const requireDigits = /(?=.*\d)/g
const requireLowLiterals = /(?=.*[a-z])/g
const requireHighLiterals = /(?=.*[A-Z])/g
const requireHighSymbols = /(?=.*[!@#$%^&*.,\-_])/g
const alphanumericPattern = /^\w+$/

class ValidationRules {
    password(required=true) {
        return [
            {
                required,
                message: 'Укажите пароль от учетной записи',
            },
            {
                min: 8,
                message: 'Пароль от учетной записи не может быть короче 8 символов',
            },
            {
                max: 20,
                message: 'Пароль от учетной записи не может быть длиннее 20 символов',
            },
            {
                max: 20,
                min: 8,
                pattern: passwordStringPattern,
                message: 'Пароль может содержать только латиницу, цифры и некоторые спецсимволы',
            },
        ]
    }

    newPassword = [
        {
            min: 8, 
            max: 20,
            message: 'Минимальная длина пароля 8 символов, максимальная - 20'
        },
        {
            pattern: passwordStringPattern,
            message: 'Недопустимые символы в пароле',
        },
        {
            pattern: requireDigits,
            message: 'В пароле должна быть минимум одна цифра'
        },
        {
            pattern: requireHighLiterals,
            message: 'В пароле должна быть минимум одна большая литера латиницы'
        },
        {
            pattern: requireLowLiterals,
            message: 'В пароле должна быть минимум одна маленькая литера латиницы'
        },
        {
            pattern: requireHighSymbols,
            message: 'Используйте минимум один символ из набора: !@#$%^&*.,-_'
        }
    ]

    login() {
        return [
            {
                required: true,
                message: 'Укажите логин',
            },
            {
                min: 3,
                message: 'Логин не может быть короче 3-х символов',
            },
            {
                max: 20,
                message: 'Логин не может быть длиннее 20-ти символов',
            },
            {
                pattern: alphanumericPattern,
                message: 'Логин может содержать только латиницу, цифры и "_"',
            },
        ]
    }

    confirmPassword(required=true, fieldname='password') {
        return [
            {
                required: true,
                message: 'Повторите пароль',
            },
            ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue(fieldname) === value) {
                    return Promise.resolve();
                  }
    
                  return Promise.reject(new Error('Пароли не совпадают, проверьте правильность ввода'));
                },
              }),
        ]
    }

    name(required = true, min = 3, max = 30) {
        return [
            {
                required,
                message: 'Укажите ФИО/имя пользователя',
            },
            {
                min,
                message: `ФИО/имя не может быть короче ${min} символов`,
            },
            {
                max,
                message: `ФИО/имя не может быть длиннее ${max} символов`,
            },
            {
                pattern: limitedStringPattern,
                message: 'ФИО/имя может содержать только буквы, цифры и точки/пробелы',
            },
        ]
    }

    email(required=true) {
        return [
            {
                required,
                type: 'email',
                message: 'Укажите валидный почтовый адрес',
            },
        ]
    }

    phone(required=true) {
        return [
            {
                required,
                pattern: /^\+[1-9]{1}[0-9]{11,14}$/,
                message: 'Укажите номер телефона в формате "+380ХХХХХХХХХ"',
            },
        ]
    }

    universalName(required = true, extended = false) {
        return [
            {
                required,
                message: 'Обязательно к заполнению'
            },
            {
                pattern: extended ? defaultStringPattern : limitedStringPattern,
                message: 'Название может содержать только буквы, цифры и пробелы.',
            },
            {
                max: 250,
                message: 'Это поле может содержать максимум 250 символов',
            }
        ]
    } 

    universalString(required=true, extended = true, max = 250) {
        return [
            {
                required,
                message: 'Обязательно к заполнению'
            },
            {
                pattern: extended ? extendedStringPattern : defaultStringPattern,
                message: 'Поле может содержать только буквы, цифры и некоторые спецсимволы',
            },
            {
                max,
                message: `Это поле может содержать максимум ${max} символов`,
            }
        ]
    }

    notEmpty(message) {
        return [
            {
                required: true,
                message: message ?? 'Поле не может быть пустым.'
            }
        ]
    }

    selectRequiered = [
        {
            required: true,
            message: 'Необходимо выбрать из списка'
        }
    ]

    validateDates(notEarlier, pastErrMessage='Дата не может быть в прошлом', noDateErrMessage='Не указана опорная дата') {
        return [
            ({ getFieldValue }) => ({
                validator(_, value) {
                    if (!notEarlier) {
                        return Promise.reject(new Error(noDateErrMessage));
                    } 
                    if (!value || value.isAfter(notEarlier)) {
                        return Promise.resolve();
                    }
                    
                    return Promise.reject(new Error(pastErrMessage));
                },
            }),
        ]
    }

    validateYoutubeURL(errMessage='Можно использовать ссылки только на Youtube') {
        return [
            {
                type: 'url',
                message: 'Тут должна быть корректная ссылка'
            },
            ({ getFieldValue }) => ({
                validator(_, value) {
                    if (value && value.search(/youtube\.com|youtu\.be/) === -1) {
                        return Promise.reject(new Error(errMessage))
                    }

                    return Promise.resolve()
                }
            })
        ]
    }
}

export default new ValidationRules()