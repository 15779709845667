import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    path: ''
}

const baseRoute = createSlice({
    name: 'baseRoute',
    initialState,
    reducers: {
        setBaseRoute: (state, { payload }) => {
            state.path = payload
        }
    }
})

export const { setBaseRoute } = baseRoute.actions

export const selectBaseRoute = state => state.baseRoute

export default baseRoute.reducer