import { Button, List, Popconfirm, Space } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import { NotificationCard } from '../cards/NotificationCard/NotificationCard'
import { useNavigate } from 'react-router-dom'
import { useToggleDrawer } from '../../hooks/useToggleDrawer'

export const NotificationsList = React.memo(({ notifications, isLoading, deleteMutation }) => {
    const navigate = useNavigate()
    const { closeDrawer } = useToggleDrawer()
    const notficationsArray = [...notifications].sort((a, b) => b.id - a.id)
    const clearHandler = () => deleteMutation({ id: '-1' })

    return (
        <Space 
            direction='vertical'
            size='small'
            align='center'
            className='w100'
        >
            {notifications.length > 0
                ?   <List
                        loading={{
                            indicator: <LoadingOutlined/>, 
                            spinning: isLoading 
                        }}
                        itemLayout='vertical'
                        dataSource={notficationsArray}
                        renderItem={notification => (
                            <List.Item>
                                <NotificationCard 
                                    notification={notification}
                                    deleteMutation={deleteMutation}
                                    navigate={navigate}
                                    closeDrawer={closeDrawer}
                                />
                            </List.Item>
                        )}
                    />
                : null
            }
            <Popconfirm
                title='Точно очистить?'
                onConfirm={clearHandler}
            >
                <Button
                    disabled={!notifications.length > 0}
                    danger
                    size='small'
                >
                    Очистить уведомления
                </Button>
            </Popconfirm>
        </Space>
    )
})