import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectStoryFilter, setStoryFilter } from '../app/slices/storyFilterSlice'

export const useStoryFilterParams = () => {
    const dispatch = useDispatch()
    const storyFilterParams = useSelector(selectStoryFilter)
    const setStoryFilterParams = useCallback(payload => {
        const data = payload instanceof Function ? payload(storyFilterParams) : payload
        dispatch(setStoryFilter(data))
    }, [dispatch, storyFilterParams])
    return {
        storyFilterParams: useMemo(() => storyFilterParams, [storyFilterParams]), 
        setStoryFilterParams
    }
}