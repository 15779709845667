import { Col, Row, Tabs} from 'antd'
import React from 'react'
import { Logo } from '../../components/Logo'
import loginTabs from './loginTabs'

export const Login = () => {
    return (
        <Row 
            align='middle'
            justify='center' 
            className='vh100'
        >
            <Col align='center'>
                <Logo 
                    height='128' 
                    width='128'
                    fill='#1890ff'
                />
                <Tabs 
                    defaultActiveKey='login' 
                    type='card'
                    animated='true'
                    items={loginTabs}
                    destroyInactiveTabPane
                />
            </Col>
        </Row>
    )
}
