import { api, apiMethods } from '../api'

const url = 'auth'

const authApi = api.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: data => ({
                url: `${url}/login`,
                method: apiMethods.post,
                body: data
            })
        }),
        check: builder.query({
            query: () => ({
                url: `${url}/refresh`,
            }),
            keepUnusedDataFor: 0.0001
        }),
        logout: builder.mutation({
            query: () => ({
                url: `${url}/logout`,
                method: apiMethods.post
            })
        }),
        restore: builder.mutation({
            query: data => ({
                url: `${url}/restore`,
                method: apiMethods.post,
                body: data
            })
        }),
        activation: builder.query({
            query: key => ({
                url: `${url}/activation?key=${key}`
            })
        }),
        setPassword: builder.mutation({
            query: data => ({
                url: `${url}/activation`,
                method: apiMethods.post,
                body: data
            })
        })
    })
})

export const { 
    useLoginMutation, 
    useLogoutMutation, 
    useCheckQuery, 
    useRestoreMutation, 
    useActivationQuery, 
    useSetPasswordMutation, 
    useLazyCheckQuery
} = authApi