import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectOnlineUsers } from '../app/slices/onlineUsersSlice'

export const useGetOnline = (route, condition) => {
    const onlineUsers = useSelector(selectOnlineUsers)

    return useMemo(() => {
        if (condition === undefined || condition) {
            const pathArr = route?.split('/')
            const joinedPath = pathArr?.join()

            return (route ? onlineUsers.filter(user => user.paths.map(path => path.split('/', pathArr.length).join()).includes(joinedPath)) : onlineUsers).map(u => u.user)
        }

        return []
    }, [onlineUsers, route, condition])
}