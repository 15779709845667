import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    typeId: undefined,
    search: undefined,
    authorId: undefined,
    participantId: undefined,
    tagId: undefined,
    date: undefined,
    toDate: undefined,
    order: 'descend',
}

const storyFilter = createSlice({
    name: 'storyFilter',
    initialState,
    reducers: {
        setStoryFilter: (state, { payload }) => {
            state.order = payload.order
            state.search = payload.search
            state.typeId = payload.typeId
            state.authorId = payload.authorId
            state.participantId = payload.participantId
            state.tagId = payload.tagId
            state.date = payload.date
            state.toDate = payload.toDate
            state.order = payload.order
        }
    }
})

export const { setStoryFilter } = storyFilter.actions
export const selectStoryFilter = state => state.storyFilter

export default storyFilter.reducer