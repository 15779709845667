import React from 'react'
import { Button, Form, Input } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import ValidationRules from '../../../utils/ValidationRules'
import { useLoginMutation } from '../../../app/endpoints/authApi'
import { ReCaptchaFormItem } from './items/ReCaptchaFormItem'

export const LoginForm = () => {
    const [ login, { isLoading } ] = useLoginMutation({ forceRefetch: true })
    const [ loginForm ] = Form.useForm()
    const onFinishHandler = values => {
        login(values)
        loginForm.resetFields(['recaptcha'])
    }

    return (
        <Form 
            form={loginForm}
            name='login'
            autoComplete='off'
            className='login-form'
            onFinish={onFinishHandler}
        >
            <Form.Item
                name='login'
                rules={ValidationRules.login()}
            >
                <Input 
                    prefix={<UserOutlined/>} 
                    placeholder='Логин'
                />
            </Form.Item>
            <Form.Item
                name='password'
                rules={ValidationRules.password()}
            >
                <Input.Password 
                    prefix={<LockOutlined/>} 
                    placeholder='Пароль'
                />
            </Form.Item>
            <ReCaptchaFormItem/>
            <Form.Item>
                <Button 
                    type='primary'
                    htmlType='submit'
                    className='w100'
                    loading={isLoading}
                >
                    Войти
                </Button>
            </Form.Item>
        </Form>
    )
}
