import { Form } from 'antd'
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import ValidationRules from '../../../../utils/ValidationRules'

export const ReCaptchaFormItem = () => {
    return (
        <Form.Item
            name='recaptcha'
            rules={ValidationRules.notEmpty('Пройдите reCAPTCHA')}
        >
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RE_SITE_KEY}
                size='compact'
            />
        </Form.Item>
    )
}
