import React, { Suspense, useEffect } from 'react'
import { Layout } from 'antd'
import { Navbar } from '../../components/Navbar/Navbar'
import { useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Loader } from '../../components/Loader'
import { useSelector } from 'react-redux'
import { selectBaseRoute } from '../../app/slices/baseRouteSlice'
import styles from './Index.module.less'
import { AppSider } from '../../components/AppSider'
import { AppFooter } from '../../components/AppFooter/AppFooter'
import { useGetLiveUpdateQuery } from '../../app/endpoints/liveApi'

export const Index = () => {
    const [ overlayClass, setOverlayClass ] = useState(false)
    const location = useLocation()
    const baseRoute = useSelector(selectBaseRoute)
    useEffect(() => window.scrollTo(0, 0), [location])
    useGetLiveUpdateQuery({currentPath: location.pathname})

    return (
        <Layout className='mh100'>
            <AppSider
                setOverlayClass={setOverlayClass}
                overlayClass={overlayClass}
                additionalClass={styles.mainSiderCompact}
            />
            <Layout className={overlayClass && styles.mainWrapperCompact}>
                <Navbar />
                <Layout.Content className={styles.content}>
                    <Suspense fallback={<Loader size='24px'/>}>
                        <Outlet/>
                    </Suspense>
                    {location.pathname === '/' && <Navigate to={baseRoute.path}/>}
                </Layout.Content>
                <AppFooter/>
            </Layout>
        </Layout>
    )
}
