import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import config from './constants/firebaseConfig'
import { lsFCM } from './constants/localStorageVars'

// Initialize Firebase
const fbApp = initializeApp(config)
const messaging = getMessaging(fbApp)

export const getPushToken = async (setTokenState) => {
    if (await isSupported()) {
        try {
            const currentToken = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
            const storedToken = localStorage.getItem(lsFCM.token)
            
            if (!currentToken) return

            if (currentToken === storedToken) return

            if (storedToken) localStorage.setItem(lsFCM.oldToken, storedToken)

            localStorage.setItem(lsFCM.token, currentToken)
            setTokenState(true)

        } catch (err) {
            console.log('PUSH notifications is not allowed')
        }
    }
}