import { incomingNotify } from '../../utils/notifications'
import { api, apiMethods, apiTags, listId } from '../api'

let lastId

const listTag = id => ({ type: apiTags.notifications, id: id ?? listId })
const url = 'notifications'

const notificationsApi = api.injectEndpoints({
    endpoints: builder => ({
        getNotifications: builder.query({
            query: () => ({
                url,
            }),
            keepUnusedDataFor: 30,
            providesTags: result => {
                    const tags = result
                    ?   [
                            ...result?.map(res => {
                                if (lastId !== undefined && res.id > lastId) {
                                    incomingNotify(res.type, res.title, res.body)
                                }
                                return listTag(res.id)
                            }),
                            listTag()
                        ]
                    :   [ listTag() ]

                    lastId = result.length ? result[result.length - 1].id : 0

                return tags
            }
        }),
        deleteNotification: builder.mutation({
            query: ({ id }) => ({
                url: `${url}/${id}`,
                method: apiMethods.delete
            }),
            invalidatesTags: [ listTag() ]
        })
    })
})

export const { 
    useGetNotificationsQuery, 
    useLazyGetNotificationsQuery, 
    useDeleteNotificationMutation 
} = notificationsApi