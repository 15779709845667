import { notification } from 'antd'

export const systemNotify = (type, message) => {
    notification[type]({
        message: message,
        placement: 'topRight',
        duration: 3
    })
}

export const incomingNotify = (type, title, body) => {
    notification[type]({
        message: title,
        description: body,
        placement: 'bottomRight',
        duration: 15
    })
}