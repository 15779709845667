import moment, { duration } from 'moment'

class dateUtils {
    extendedTime = process.env.REACT_APP_EXTENDED_TIME
    defaultTime = process.env.REACT_APP_DEFAULT_TIME
    defaultDate = process.env.REACT_APP_DEFAULT_DATE
    dateTimeExtended = this.defaultDate + ' ' + this.extendedTime
    dateTime = this.defaultDate + ' ' + this.defaultTime
    backendDate = process.env.REACT_APP_BACKEND_DATE
    backendDateTime = this.backendDate + ' ' + this.extendedTime

    nowMoment = () => moment()
    getMoment = (dateTime, format) => dateTime ? moment(dateTime, format) : undefined
    getDate = (date, extended) => moment(date).format(extended ? this.dateTimeExtended : this.dateTime)
    getTiming = timing => moment.utc(timing * 1000).format(this.extendedTime)
    getRawTiming = timing => moment.utc(duration(timing, 'seconds').as('milliseconds'))
    getTimeDuration = time => duration(time.format(this.extendedTime)).asSeconds()
    getMinutesFromNow = time => Math.ceil(duration(time.diff(moment())).asMinutes())
    addTimeToCurrentDate = time => moment(moment().format(this.defaultDate) + ' ' + time, this.dateTimeExtended)
} 

export default new dateUtils()