import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { logout, refresh } from './slices/userSlice'
import { Mutex } from 'async-mutex'
import { lsAuth } from '../constants/localStorageVars'

export const API_URL = process.env.REACT_APP_API_URL

export const fetchParams = {
    credentials: 'include',
    mode: 'cors',
}

export const apiTags = {
    accountsTag: 'GoogleAccounts',
    syncTag: 'SyncTables',
    spFiles: 'spFiles',
    blockTypes: 'BlockTypes',
    departments: 'Departments',
    roles: 'Roles',
    nodes: 'Nodes',
    stories: 'Stories',
    singleBlock: 'SingleBlock',
    nodeStories: 'NodeStories',
    singleStory: 'SingleStory',
    storyTags: 'StoryTags',
    storyTypes: 'StoryTypes',
    storyDirectors: 'StoryDirectors',
    ticketCategories: 'TicketCategories',
    tickets: 'Tickets',
    ticketReplies: 'TicketReplies',
    nodeTypes: 'NodeTypes',
    notifications: 'Notifications',
    schedule: 'Schedule',
    scheduleRoles: 'ScheduleRoles',
    users: 'Users'
}

export const apiMethods = {
    post: 'POST',
    put: 'PUT',
    delete: 'DELETE'
}

export const listId = 'LIST'

export const mutex = new Mutex()

const baseQuery = fetchBaseQuery({ 
    baseUrl: API_URL, 
    ...fetchParams,
    prepareHeaders: headers => {
        const token = localStorage.getItem(lsAuth)
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    }
})

const baseQueryAuth = async (args, api, extraOptions) => {
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        if(!mutex.isLocked()) {
            const release = await mutex.acquire()
            try {
                const refreshResult = await baseQuery({
                        url: '/auth/refresh', 
                        method: 'GET'
                    }, api, extraOptions)     
                if (refreshResult.data) {
                    api.dispatch(refresh(refreshResult.data))
                    result = await baseQuery(args, api, extraOptions)
                } else {
                    api.dispatch(logout())
                }
            } finally {
                release()
            }
        } else {
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }
    return result
}

export const api = createApi({
    reducerPath: 'api',
    tagTypes: Object.values(apiTags),
    baseQuery: baseQueryAuth,
    endpoints: () => ({})
})
