import { isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit'
import { systemNotify } from '../../utils/notifications'

export const notificationMiddleware = api => next => action => {
    if (isRejectedWithValue(action)) {
        if (action.payload.status === 'FETCH_ERROR') systemNotify('error', 'Ошибка API')

        if (action.payload.data?.message) systemNotify('error', action.payload.data.message)
    }
    if (isFulfilled(action) && action.payload?.message) {
        systemNotify('success', action.payload?.message)
    }
    return next(action)
}