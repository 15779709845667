import { createSlice } from '@reduxjs/toolkit'
import dateUtils from '../../utils/dateUtils'

export const nodesInitialState = {
    date: (dateUtils.nowMoment()).subtract(1, 'months').format(dateUtils.backendDate),
    toDate: (dateUtils.nowMoment()).add(1, 'month').format(dateUtils.backendDate),
    orderBy: 'releaseDate',
    order: 'descend',
    type: undefined
}

const nodeFilter = createSlice({
    name: 'nodeFilter',
    initialState: nodesInitialState,
    reducers: {
        setNodeFilter: (state, { payload }) => {
            state.date = payload.date
            state.toDate = payload.toDate
            state.orderBy = payload.orderBy
            state.order = payload.order
            state.type = payload.type
        }
    }
})

export const { setNodeFilter } = nodeFilter.actions
export const selectNodeFilter = state => state.nodeFilter

export default nodeFilter.reducer