import { Button, Card, Space } from 'antd'
import React from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import styles from './NotificationCard.module.less'
import dateUtils from '../../../utils/dateUtils'
import { spaceCenter } from '../../../constants/layouts'

export const NotificationCard = React.memo(({ 
    notification, 
    deleteMutation, 
    navigate, 
    closeDrawer 
}) => {
    const navigateToHandler = () => {
        navigate(notification.entityLink)
        closeDrawer()
    }

    const deleteHandler = () => deleteMutation({
        id: notification.id
    })

    return (
        <Card 
            key={notification.id}
            size='small'
            className={styles.notification}
            title={
                <Space 
                    {...spaceCenter}
                >
                    <InfoCircleOutlined className={styles[notification.type]}/>
                    {notification.title}
                </Space>
            }
            actions={[
                <Button 
                    type='link'
                    disabled={!notification.entityLink}
                    onClick={navigateToHandler}
                    size='small'
                >
                    Просмотреть
                </Button>,
                <Button 
                    danger
                    type='link'
                    size='small'
                    onClick={deleteHandler}
                >
                    Удалить
                </Button>
            ]}
        >
            <p className={styles.body}>
                {notification.body}
            </p>
            <Card.Meta
                description={dateUtils.getDate(notification.created)}
            />
        </Card>
    )
})
