import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    order: 'descend',
    orderBy: 'createdAt',
    status: 'all',
    category: undefined,
    search: undefined
}

const ticketFilter = createSlice({
    name: 'ticketFilter',
    initialState,
    reducers: {
        setTicketFilter: (state, { payload }) => {
            state.order = payload.order
            state.orderBy = payload.orderBy
            state.status = payload.status
            state.search = payload.search
            state.category = payload.category
        }
    }
})

export const { setTicketFilter } = ticketFilter.actions
export const selectTicketFilter = state => state.ticketFilter

export default ticketFilter.reducer