import { notification } from 'antd';

export const checkUpload = maxSize => {
    return function _checkUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
        if (!isJpgOrPng) {
        notification.error({message: 'Можно загружать только файлы формата JPEG и PNG'})
        }
        const limit = file.size / 1024 / 1024 < maxSize;
        if (!limit) {
            notification.error({message: `Файлы должны быть меньше ${maxSize}МБ`})
        }
        return isJpgOrPng && limit;
    }
};
